<template>
  <div v-bind:id="name" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height="200">
    <div class="text-center top-30">
      <h1 class="mt-4 font-700 font-22">{{ $t(title) }}</h1>
      <p class="boxed-text-xl">{{ $t(sub_title) }}</p>
      <div class="row mb-0">
        <div class="col-6">
          <a href="#" v-on:click="close()" class="ms-3 btn btn-sm rounded-sm btn-full text-uppercase font-900 bg-red-dark">{{ $t('global.back') }}</a>
        </div>
        <div class="col-6">
          <a href="#" v-on:click="confirm()" class="me-3 btn btn-sm rounded-sm btn-full text-uppercase font-900 bg-green-dark">{{ $t('global.confirm') }}</a>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { menus } from "@/app/app.themes.js";

export default {
  props: ["name"],
  data() {
    return {
      title: "",
      sub_title: "",
      // Private variables
      promise: undefined,
    };
  },
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.sub_title = opts.sub_title;
      menus.open(this.name);
      return new Promise((resolve) => {
        this.promise = resolve;
      });
    },
    close() {
      menus.close();
    },
    confirm() {
      menus.close();
      this.promise(true);
    },
  },
};
</script>