<template>
  <div>
    <div class="page-content">
      <div class="card mt-2">
        <div class="content mb-0">
          <a href="#" v-on:click="confirmDelete()" class="btn btn-xs float-end rounded-sm shadow-xl text-uppercase font-800 bg-highlight mt-2"><i class="far fa-trash-alt"></i></a>
          <img class="mt-0 float-start" v-bind:src="require('@/assets/images/icon-152x152.png')" alt="LinfoNeo" width="55">
          <h3 class="linfoneo font-800 mt-3 mb-3 text-center">{{ $t('home.title') }}</h3>
          <!-- <h3>{{ $t('home.title') }}</h3> -->
          <p class="mb-2 mt-4 text-justify">{{ $t('home.title-sub') }}</p>
          <div class="divider mt-0 mb-3"></div>
          <b-collapse id="collapse-phantom" accordion="variables"></b-collapse>
          <!-- postNAC-breast -->
          <div class="d-flex no-effect ">
            <div class="pt-2" v-b-toggle="'collapse-postNAC-breast'" aria-expanded="true">
              <span><span class="font-600 h6 ">1</span>. {{ $t('variables.postNAC-breast') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
            <div class="ms-auto me-4 pe-2">
              <div class="custom-control ios-switch ios-switch-icon">
                <!-- v-bind:class="{'ios-switch-icon-disabled': variables.postNAC_breast === undefined }" -->
                <input type="checkbox" class="ios-input" id="toggle-postNAC-breast" v-model="variables.postNAC_breast">
                <label class="custom-control-label" for="toggle-postNAC-breast"></label>
                <span class="text-uppercase">{{ $t('global.yes') }}</span>
                <span class="text-uppercase">{{ $t('global.no') }}</span>
              </div>
            </div>
          </div>
          <b-collapse id="collapse-postNAC-breast" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.postNAC-breast-help') }}</small></p>
          </b-collapse>
          <div class="divider mt-3 mb-3"></div>

          <!-- postNAC-restaging -->
          <div class="d-flex no-effect">
            <div class="pt-2" v-b-toggle="'collapse-postNAC-restaging'" aria-expanded="true">
              <span><span class="font-600 h6 ">2</span>. {{ $t('variables.postNAC-restaging') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
            <div class="ms-auto me-4 pe-2">
              <div class="custom-control ios-switch ios-switch-icon">
                <input type="checkbox" class="ios-input" id="toggle-postNAC-restaging" v-model="variables.postNAC_restaging">
                <label class="custom-control-label" for="toggle-postNAC-restaging"></label>
                <span class="text-uppercase">{{ $t('global.positive') }}</span>
                <span class="text-uppercase">{{ $t('global.negative') }}</span>
              </div>
            </div>
          </div>
          <b-collapse id="collapse-postNAC-restaging" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.postNAC-restaging-help') }}</small></p>
          </b-collapse>
          <div class="divider mt-3 mb-3"></div>

          <!-- NAC-regimen -->
          <div class="d-flex no-effect">
            <div class="pt-2" v-b-toggle="'collapse-NAC-regimen'" aria-expanded="true">
              <span><span class="font-600 h6 ">3</span>. {{ $t('variables.NAC-regimen') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
          </div>
          <b-collapse id="collapse-NAC-regimen" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.NAC-regimen-help') }}</small></p>
          </b-collapse>
          <!--  <div class="row mt-1 mb-0">
            <div class="col-12" v-for="(index) in ['1','2','3','4']" :key="index">
              <div class="form-check icon-check">
                <input class="form-check-input" type="radio" name="NACregimenOptions" v-model="variables.hystological_type" value="CDI" :id="'radioNACType' + index">
                <label class="form-check-label" :for="'radioNACType' + index">{{ $t('variables.NAC-type' + index) }}</label>
                <i class="icon-check-1 far fa-circle color-gray-dark font-16"></i>
                <i class="icon-check-2 fa fa-check-circle font-16 color-green-dark"></i>
              </div>
            </div>
          </div> -->
          <div class="input-style no-borders no-icon mb-4 limited-select">
            <select id="select-NAC-regimen" class="" v-model="variables.NAC_regimen">
              <option value="">{{ $t('global.select-value') }}</option>
              <option value="type1">{{ $t('variables.NAC-type1') }}</option>
              <option value="type2">{{ $t('variables.NAC-type2') }}</option>
              <option value="type3">{{ $t('variables.NAC-type3') }}</option>
              <option value="type4">{{ $t('variables.NAC-type4') }}</option>
            </select>
            <span v-if="!variables.NAC_regimen"><i class="fa fa-chevron-down"></i></span>
            <i class="fa fa-check valid color-green-dark" v-bind:class="{'disabled': !variables.NAC_regimen  }"></i>
            <em></em>
          </div>
          <div class="mt-3 mb-3"></div>

          <!-- ki67 -->
          <div class="d-flex no-effect">
            <div class="pt-2" v-b-toggle="'collapse-ki67'" aria-expanded="true">
              <span><span class="font-600 h6 ">4</span>. {{ $t('variables.ki67') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
          </div>
          <b-collapse id="collapse-ki67" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.ki67-help') }}</small></p>
          </b-collapse>
          <div class="row mt-1 mb-0">
            <div class="col-6">
              <div class="form-check icon-check">
                <input class="form-check-input" type="radio" name="ki67TypeOptions" v-model="variables.ki67" value="<=" id="radioKi67Minus">
                <label class="form-check-label" for="radioKi67Minus">&le; 14%</label>
                <i class="icon-check-1 far fa-circle color-gray-dark font-16"></i>
                <i class="icon-check-2 fa fa-check-circle font-16 color-green-dark"></i>
              </div>
            </div>
            <div class="col-6">
              <div class="form-check icon-check">
                <input class="form-check-input" type="radio" name="ki67TypeOptions" v-model="variables.ki67" value=">" id="radioKi67Plus">
                <label class="form-check-label" for="radioKi67Plus">&gt; 14%</label>
                <i class="icon-check-1 far fa-circle color-gray-dark font-16"></i>
                <i class="icon-check-2 fa fa-check-circle font-16 color-green-dark"></i>
              </div>
            </div>
          </div>
          <div class="divider mt-3 mb-3"></div>

          <!-- biomolecular-subtype -->
          <div class="d-flex no-effect">
            <div class="pt-2" v-b-toggle="'collapse-biomolecular-subtype'" aria-expanded="true">
              <span><span class="font-600 h6 ">5</span>. {{ $t('variables.biomolecular-subtype') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
          </div>
          <b-collapse id="collapse-biomolecular-subtype" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.biomolecular-subtype-help') }}</small></p>
          </b-collapse>
          <div class="input-style no-borders no-icon mb-4">
            <select id="select-biomolecular-subtype" v-model="variables.biomolecular_subtype">
              <option value="">{{ $t('global.select-value') }}</option>
              <option value="ePhP">ER+ / HER2+</option>
              <option value="eNhP">ER- / HER2+</option>
              <option value="eNhN">ER- / HER2-</option>
              <option value="ePhN">ER+ / HER2-</option>
            </select>
            <span v-if="!variables.biomolecular_subtype"><i class="fa fa-chevron-down"></i></span>
            <i class="fa fa-check valid color-green-dark" v-bind:class="{'disabled': !variables.biomolecular_subtype  }"></i>
            <em></em>
          </div>
          <div class="mt-3 mb-3"></div>

          <!-- hystological-type -->
          <div class="d-flex no-effect">
            <div class="pt-2" v-b-toggle="'collapse-hystological-type'" aria-expanded="true">
              <span><span class="font-600 h6 ">6</span>. {{ $t('variables.hystological-type') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
          </div>
          <b-collapse id="collapse-hystological-type" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.hystological-type-help') }}</small></p>
          </b-collapse>
          <div class="row mt-1 mb-0">
            <div class="col-12">
              <div class="form-check icon-check">
                <input class="form-check-input" type="radio" name="hystologicalTypeOptions" v-model="variables.hystological_type" value="CDI" id="radioHystoCDI">
                <label class="form-check-label" for="radioHystoCDI">{{ $t('variables.hystological-CDI') }}</label>
                <i class="icon-check-1 far fa-circle color-gray-dark font-16"></i>
                <i class="icon-check-2 fa fa-check-circle font-16 color-green-dark"></i>
              </div>
            </div>
            <div class="col-12">
              <div class="form-check icon-check">
                <input class="form-check-input" type="radio" name="hystologicalTypeOptions" v-model="variables.hystological_type" value="CLI" id="radioHystoCLI">
                <label class="form-check-label" for="radioHystoCLI">{{ $t('variables.hystological-CLI') }}</label>
                <i class="icon-check-1 far fa-circle color-gray-dark font-16"></i>
                <i class="icon-check-2 fa fa-check-circle font-16 color-green-dark"></i>
              </div>
            </div>
            <div class="col-12">
              <div class="form-check icon-check">
                <input class="form-check-input" type="radio" name="hystologicalTypeOptions" v-model="variables.hystological_type" value="others" id="radioHystoOthers">
                <label class="form-check-label" for="radioHystoOthers">{{ $t('variables.hystological-others') }}</label>
                <i class="icon-check-1 far fa-circle color-gray-dark font-16"></i>
                <i class="icon-check-2 fa fa-check-circle font-16 color-green-dark"></i>
              </div>
            </div>
          </div>
          <div class="divider mt-3 mb-3"></div>

          <!-- clinical-Tstage -->
          <div class="d-flex no-effect">
            <div class="pt-2" v-b-toggle="'collapse-clinical-Tstage'" aria-expanded="true">
              <span><span class=" font-600 h6 ">7</span>. {{ $t('variables.clinical-Tstage') }}</span>
              <i class="far fa-question-circle ml-5"></i>
            </div>
          </div>
          <b-collapse id="collapse-clinical-Tstage" accordion="variables">
            <p class="mb-0 pb-0"><small>{{ $t('variables.clinical-Tstage-help') }}</small></p>
          </b-collapse>
          <div class="input-style no-borders no-icon mb-4">
            <select id="select-clinical-Tstage" v-model="variables.clinical_Tstage">
              <option value="">{{ $t('global.select-value') }}</option>
              <option value="cT1">{{ $t('variables.clinical-cT1') }}</option>
              <option value="cT2">{{ $t('variables.clinical-cT2') }}</option>
              <option value="cT3">{{ $t('variables.clinical-cT3') }}</option>
              <option value="cT4">{{ $t('variables.clinical-cT4') }}</option>
            </select>
            <span v-if="!variables.clinical_Tstage"><i class="fa fa-chevron-down"></i></span>
            <i class="fa fa-check valid color-green-dark" v-bind:class="{'disabled': !variables.clinical_Tstage  }"></i>
            <em></em>
          </div>
          <div class="mt-3 mb-3"></div>

          <!-- Buttons -->
          <a class="mb-3 btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4"
             v-bind:class="{'opacity-30': !isValid  }" v-on:click="showScore()">{{ $t('home.calculate') }}</a>
          <div class="clear"></div>
        </div>
      </div>
    </div>

    <div id="menu-points" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height="410">
      <div class="menu-title text-center">
        <h1>{{ $t('home.total-score')}}: <span class="font-800">{{ totalScore.score }} <span class="h4">({{ totalScore.probability }}%)</span><em /></span></h1>
      </div>
      <div class="divider divider-margins mb-n2"></div>
      <div class="content">
        <div class="d-flex mb-1" v-for="p in points" v-bind:key="p.var">
          <div class="align-self-center">
            <i class="fa fa-angle-right"></i>
            <a href="#" class="mb-0 color-theme opacity-60 font-13">{{ ' ' +  $t('variables.' + p.var) }}</a>
          </div>
          <div class="align-self-center ms-auto">
            <h6 class="mb-0 font-11 badge badge-fixed-3 bg-gray-dark bg-highlight">{{ p.score }}</h6>
          </div>
        </div>
        <a href="#" v-on:click="close()" class="btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase mt-4">{{ $t('home.back-to-home') }}</a>
      </div>
    </div>

    <modal-confirm ref="confirmDelete" name="confirm-delete" />

  </div>
</template>

<script>
import { menus, themes } from "@/app/app.themes.js";
import modalConfirm from "@/components/menus/modal.confirm.vue";
import { BCollapse } from "bootstrap-vue";
import appInsights from "@/app/app.appinsights.js";

export default {
  components: { modalConfirm, BCollapse },
  name: "Home",
  computed: {
    isValid() {
      return (
        this.variables.NAC_regimen !== "" &&
        this.variables.biomolecular_subtype !== "" &&
        this.variables.clinical_Tstage !== "" &&
        this.variables.hystological_type !== undefined &&
        this.variables.ki67 !== undefined
      );
    },
    points() {
      let points = [];
      if (this.isValid) {
        let score = 0;

        // postNAC-breast
        if (this.variables.postNAC_breast === true) score = 100;
        else score = 0;
        points.push({ var: "postNAC-breast", score: score });

        // postNAC-restaging
        if (this.variables.postNAC_restaging === true) score = 0;
        else score = 98;
        points.push({ var: "postNAC-restaging", score: score });

        // NAC-regimen
        if (this.variables.NAC_regimen === "type1") score = 32;
        else if (this.variables.NAC_regimen === "type2") score = 25;
        else if (this.variables.NAC_regimen === "type3") score = 70;
        else if (this.variables.NAC_regimen === "type4") score = 0;
        points.push({ var: "NAC-regimen", score: score });

        // ki67
        if (this.variables.ki67 === ">") score = 50;
        else if (this.variables.ki67 === "<=") score = 0;
        points.push({ var: "ki67", score: score });

        // biomolecular-subtype
        if (this.variables.biomolecular_subtype === "ePhP") score = 56;
        else if (this.variables.biomolecular_subtype === "eNhP") score = 89;
        else if (this.variables.biomolecular_subtype === "eNhN") score = 73;
        else if (this.variables.biomolecular_subtype === "ePhN") score = 0;
        points.push({ var: "biomolecular-subtype", score: score });

        // hystological-type
        if (this.variables.hystological_type === "CDI") score = 25;
        else if (this.variables.hystological_type === "CLI") score = 0;
        else if (this.variables.hystological_type === "others") score = 65;
        points.push({ var: "hystological-type", score: score });

        // clinical-Tstage
        if (this.variables.clinical_Tstage === "cT1") score = 0;
        else if (this.variables.clinical_Tstage === "cT2") score = 22;
        else if (this.variables.clinical_Tstage === "cT3") score = 28;
        else if (this.variables.clinical_Tstage === "cT4") score = 10;
        points.push({ var: "clinical-Tstage", score: score });
      }
      return points;
    },
    totalScore() {
      let total = 0;
      this.points.forEach((p) => {
        total += p.score;
      });
      let probs = 0;
      let probs_points = [0, 83, 127, 171, 216, 260, 304, 348, 393, 437, 9999];
      for (let i = 0, l = probs_points.length; i < l; i++) {
        if (probs_points[i] <= total && total < probs_points[i + 1]) {
          probs = i * 10;
          break;
        }
      }

      return { score: total, probability: probs };
    },
  },
  data() {
    return {
      variables: {},
    };
  },
  mounted() {
    themes.init_template();
    this.init();
  },
  methods: {
    async confirmDelete() {
      const confirmed = await this.$refs.confirmDelete.show({
        title: "messages.confirm-delete",
        sub_title: "messages.confirm-delete-sub",
      });
      if (confirmed) {
        this.init();
      }
    },
    init() {
      this.variables = {
        postNAC_breast: false,
        postNAC_restaging: false,
        NAC_regimen: "",
        biomolecular_subtype: "",
        clinical_Tstage: "",
        hystological_type: undefined,
        ki67: undefined,
      };
      this.$root.$emit("bv::toggle::collapse", "collapse-phantom");
    },
    showScore() {
      if (appInsights) {
        appInsights.trackEvent({
          name: "TotalScore",
          properties: this.totalScore,
        });
      }
      menus.open("menu-points");
    },
    close() {
      menus.close();
      window.scrollTo(0, 0);
    },
  },
};
</script>
